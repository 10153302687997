<template>
  <div class="container">
    <div ref="box">
      <div ref="header">
        <a-page-header style="border-bottom: 1px solid rgb(235, 237, 240)" title="活动任务管理">
        </a-page-header>
      </div>
      <div ref="form">
        <a-form :form="form" layout="inline" @submit="handleSubmit" style="padding: 15px 0" ref="form">
          <a-form-item label="用户手机号">
            <a-input style="width: 180px" v-decorator="['user_mobile_search', { rules: [{ required: false }] }]">
            </a-input>
          </a-form-item>
          <a-form-item label="活动">
            <a-select style="width: 180px" v-decorator="['activity_id_search', { rules: [{ required: false }] }]">
              <a-select-option :value="item.id" v-for="item in activityList" :key="item.id">{{ item.title
              }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="待处理">
            <a-select style="width: 180px" v-decorator="['is_pending_search', { rules: [{ required: false }] }]">
              <a-select-option value="1">是</a-select-option>
              <a-select-option value="2">否</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="已完结">
            <a-select style="width: 180px" v-decorator="['is_completed_search', { rules: [{ required: false }] }]">
              <a-select-option value="1">是</a-select-option>
              <a-select-option value="2">否</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="getExport(0)">
              搜索
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <a-table :columns="columns" :data-source="taskList" :pagination="false" :scroll="{ x: 1000, y: table_h }"
        :row-key="(record) => record.id" size="middle" ref="tableList">
        <img slot="img" slot-scope="img" :src="img" />
        <span slot="price" slot-scope="price">{{ price }}元</span>
        <span slot="oldPrice" slot-scope="oldPrice">{{ oldPrice }}元</span>
        <span slot="percent" slot-scope="percent">{{ percent }}%</span>
        <span slot="status" slot-scope="status">
          <a-icon type="check" v-if="status == 1" style="color: #0092dc" />
          <a-icon type="close" v-if="status == 0" style="color: #ed5565" />
        </span>
        <span slot="btns" slot-scope="btns, num">
          <a-button class="editable-add-btn" v-for="btn in btns" :key="btn" @click="handel(btn, num)" size="small"
            style="margin: 5px">
            {{ btn }}
          </a-button>
        </span>
      </a-table>
      <div ref="pagination">
        <a-pagination v-if="pageshow" :default-current="currentPage" :defaultPageSize="defaultPageSize"
          :page-size-options="pageSizeOptions" show-size-changer show-quick-jumper :total="total_num"
          :show-total="(total) => `共 ${total} 条`" @showSizeChange="onShowSizeChange" @change="onPageChange">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
      <a-modal v-model="visible" :title="modelTitle" width="700px" :confirm-loading="confirmLoading" ok-text="确认"
        cancel-text="取消" footer="">

        <a-descriptions layout="vertical" bordered v-if="taskinfo">
          <a-descriptions-item label="用户" :span="3">
            <img :src="taskinfo.user.avatar" alt="" style="width:60px;height:60px;border-radius: 30px;margin-right:10px">
            <span style="color:#333">{{ taskinfo.user.nickname }}</span>
            <span>（手机号：{{ taskinfo.user.mobile }}）</span>
          </a-descriptions-item>
          <a-descriptions-item label="已发放奖励金额">
            {{ taskinfo.total_reward_amount }}元
            <a-icon type="edit" style="color:#1890ff;margin-left:10px" @click="changeRewardAmount"></a-icon>
          </a-descriptions-item>
          <a-descriptions-item label="是否已完结">
            {{ taskinfo.is_completed == 1 ? '是' : '否' }}
            <a-switch v-if="taskinfo.is_completed == 0" style="margin-left:5px" @change="changePending"></a-switch>
          </a-descriptions-item>
          <a-descriptions-item label="是否待处理">
            {{ taskinfo.is_pending == 1 ? '是' : '否' }}
          </a-descriptions-item>
          <a-descriptions-item label="做单步骤" :span="3">
            <a-collapse v-model="activeKey" :expand-icon-position="expandIconPosition" style="background:#eee">
              <a-collapse-panel v-for="item in taskinfo.activity_tasks_step" :key="item.step_id.toString()"
                :header="item.step_title">
                <!-- <a-form :form="form" @submit="handleSubmit" style="padding: 15px 0" :label-col="{ span: 3 }" :wrapper-col="{ span: 12 }"> -->
                <a-form-item label="名称">
                  <a-input v-model="tasksStep[item.step_id].step_title">
                  </a-input>
                </a-form-item>
                <a-form-item label="描述">
                  <a-textarea v-model="tasksStep[item.step_id].step_description">
                  </a-textarea>
                </a-form-item>
                <a-form-item label="状态">
                  <a-select style="width: 180px" v-model="tasksStep[item.step_id].step_status"
                    @change="changeStepStatus($event, item.step_id)">
                    <a-select-option value="not_started">未开始</a-select-option>
                    <a-select-option value="in_progress">进行中</a-select-option>
                    <a-select-option value="under_review">待审核</a-select-option>
                    <a-select-option value="approved">审核通过</a-select-option>
                    <a-select-option value="rejected">审核不通过</a-select-option>
                  </a-select>
                </a-form-item>
                <a-form-item label="原因" v-if="tasksStep[item.step_id].step_status == 'rejected'">
                  <a-input v-model="tasksStep[item.step_id].rejected_reason">
                  </a-input>
                </a-form-item>
                <a-row v-if="item.is_resources && item.resources_url"
                  style="color:rgba(0, 0, 0, 0.85);margin-bottom:10px">
                  提交的文本：{{ item.resources_url }}
                </a-row>
                <a-row v-if="item.is_screenshot && item.screenshot_urls.length > 0" style="color:rgba(0, 0, 0, 0.85)">
                  提交的图片：
                  <a-col style="padding-top:10px">
                    <img :src="item1" alt="" v-for="(item1, index) in item.screenshot_urls" :key="index"
                      style="margin-right:10px;margin-bottom:10px;width:100px;height:200px" v-image-preview>
                  </a-col>
                </a-row>
                <!-- </a-form> -->
                <span slot="extra">
                  {{ item.step_status == 'not_started' ? '未开始' :
                    item.step_status == 'in_progress' ? '进行中' :
                      item.step_status == 'under_review' ? '待审核' :
                        item.step_status == 'approved' ? '审核通过' : '审核不通过'
                  }}
                  <a-button size="small" type="primary" style="margin-left:20px" @click.stop="saveStep(item.step_id)"
                    v-if="activeKey.includes(item.step_id.toString())">保存</a-button>
                </span>
              </a-collapse-panel>
              <!-- <a-collapse-panel key="2" header="This is panel header 2" :disabled="false">
                <p>222</p>
                <a-icon slot="extra" type="setting" @click="handleClick" />
              </a-collapse-panel>
              <a-collapse-panel key="3" header="This is panel header 3">
                <p>333</p>
                <a-icon slot="extra" type="setting" @click="handleClick" />
              </a-collapse-panel> -->
            </a-collapse>
          </a-descriptions-item>
          <a-descriptions-item label="认证信息" :span="3">

            <a-table :columns="columnsDetail" :data-source="taskinfo.share_code.platform_auth" :pagination="false">
              <span slot="avatar" slot-scope="avatar">
                <img :src="avatar" alt="" style="width:60px;height:60px;border-radius: 30px;">
              </span>
            </a-table>
          </a-descriptions-item>
        </a-descriptions>
      </a-modal>

      <a-modal v-model="visibleAmount" title="修改奖励金额" :confirm-loading="confirmLoading" ok-text="确定" @ok="handleAmountOk"
        cancel-text="取消">
        <a-form :form="Amountform" @submit="handleSubmit">
          <!-- <a-form-item
          label="当前口令："
          style="display: flex; justify-content: flex-start; flex-dirction: row"
        >
          <span>{{ code }}</span>
        </a-form-item> -->
          <a-form-item label="金额：">
            <a-input-number step="0.01" :min="0.01" v-decorator="[
              'amount',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入金额!',
                  },
                ],
              },
            ]" />
          </a-form-item>
          <a-form-item label="类型">
            <a-radio-group v-decorator="[
              'pm',
              {
                rules: [{ required: true, message: '请选择类型!' }],
              },
            ]">
              <a-radio :value="1"> 增加 </a-radio>
              <a-radio :value="0"> 减少 </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="是否分佣">
            <a-radio-group v-decorator="[
              'is_allocation_fee',
              {
                rules: [{ required: true, message: '请选择类型!' }],
              },
            ]">
              <a-radio :value="1"> 是 </a-radio>
              <a-radio :value="0"> 否 </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: "Car",
  data() {
    return {
      color: "",
      color1: "",
      activeKey: "",
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      export: 0,
      addform: this.$form.createForm(this, { name: "city_add" }),
      Amountform: this.$form.createForm(this, { name: "Amountform" }),
      columns: [
        // {
        //   title: "ID",
        //   dataIndex: "share_code_id",
        //   align: "center",
        // },
        {
          title: "用户名称",
          dataIndex: "user.nickname",
          align: "center",
        },
        {
          title: "用户手机号",
          dataIndex: "user.mobile",
          align: "center",
        },
        {
          title: "任务名称",
          dataIndex: "task_template_name",
          align: "center",
        },
        {
          title: "待处理",
          dataIndex: "is_pending",
          align: "center",
          customRender: function (t, r) {
            return r.is_pending == 1 ? "是" : "否"
          }
        },
        {
          title: "已完结",
          dataIndex: "is_completed",
          align: "center",
          customRender: function (t, r) {
            return r.is_completed == 1 ? "是" : "否"
          }
        },
        {
          title: "创建时间",
          dataIndex: "created_at",
          align: "center",
        },
        {
          title: "操作",
          dataIndex: "btns",
          scopedSlots: { customRender: "btns" },
          align: "center",
        },
      ],
      columnsDetail: [
        {
          title: "头像",
          dataIndex: "info.avatar",
          align: "center",
          scopedSlots: { customRender: "avatar" },
        },
        {
          title: "昵称",
          dataIndex: "info.nickname",
          align: "center",
        },
        {
          title: "粉丝数量",
          dataIndex: "info.fans_count",
          align: "center",
        },
        {
          title: "平台",
          dataIndex: "platform_class.platform_name",
          align: "center",
        },
      ],
      visible: false,
      modelTitle: "",
      fileList: [],
      confirmLoading: false,
      uploadBtn: true,
      pageshow: false,
      currentPage: 1,
      total_num: 0,
      defaultPageSize: 10,
      pageSizeOptions: ["10", "20", "30", "50", "100"],
      table_h: "",
      activityList: [],
      taskList: [],
      visibleAmount: false,
      taskinfo: ''
      // canUpload: "",
      // gid: 49,
      // top_board: [{}],
      // task: [{}]
    };
  },
  mounted() {
    window.addEventListener("resize", () => this.changeTableH(), false);
    this.getSearchList({});
    this.getActivity()
  },
  methods: {
    changeTableH() {
      this.table_h =
        this.$refs.box.offsetHeight -
        this.$refs.form.offsetHeight -
        this.$refs.header.offsetHeight -
        this.$refs.pagination.offsetHeight -
        45;
      if (this.table_h <= 250) {
        this.table_h = 400;
      }
    },
    //获取活动
    getActivity() {
      var that = this;
      that.axios
        .get("/admin/activityTask/activitySelect")
        .then((res) => {
          if (that.$code(res)) {
            that.activityList = res.data.data;
          }
        });
    },
    changeStepStatus(e, step_id) {
      var tasksStep = this.tasksStep
      tasksStep[step_id].step_status = e
      this.tasksStep = tasksStep
      this.$forceUpdate();
    },
    saveStep(step_id) {
      var data = {
        step_id: step_id
      }
      for (var key in this.tasksStep[step_id]) {
        if (this.tasksStep[step_id][key] != '') {
          data[key] = this.tasksStep[step_id][key];
        }
      }
      if (data.rejected_reason && data.step_status != 'rejected') {
        delete data.rejected_reason
      }
      var that = this;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.axios
        .post("/admin/activityTask/tasksStepModify", data)
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            that.$message.success('保存成功')
            that.getOne(that.taskinfo.task_id)
            // var tasksStep=that.tasksStep
            // var taskinfo=that.taskinfo
            // for(var i=0;i<taskinfo.activity_tasks_step.length;i++){
            // if(taskinfo.activity_tasks_step[i].step_id==step_id){
            //   taskinfo.activity_tasks_step[i].step_status=data.step_status;
            //   break
            // }
            // }
            // that.tasksStep=tasksStep
          }
        });
    },
    changePending() {
      var that = this;
      that.$confirm({
        title: "提示",
        content: "是否完结此活动任务",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
          that.axios
            .post("/admin/activityTask/markAsCompleted", { task_id: that.taskinfo.task_id })
            .then((res) => {
              that.$message.loading({
                content: "加载中..",
                key: "load",
                duration: 0.00001,
              });
              if (that.$code(res)) {
                that.getOne(that.taskinfo.task_id)
              }
            });

        },
      });

    },
    //修改奖励金额
    changeRewardAmount() {
      this.visibleAmount = true;
      this.confirmLoading = false;
      this.$nextTick(() => {
        this.Amountform.setFieldsValue({
          amount: "",
          pm: "",
          is_allocation_fee: ""
        });
      });
    },
    handleAmountOk() {
      var that = this;
      that.confirmLoading = true;
      that.Amountform.validateFields((error, values) => {
        if (!error) {
          var data = values;
          that.axios
            .post(
              "/admin/activityTask/setRewardAmount/" + that.taskinfo.share_code_id,
              data
            )
            .then((res) => {
              that.$message.loading({
                content: "加载中..",
                key: "load",
                duration: 0.00001,
              });
              if (that.$code(res)) {
                that.$message.success("奖励金额修改成功");
                that.getOne(that.taskinfo.task_id)
              }
              that.visibleAmount = false;
              that.confirmLoading = false;
            });
        } else {
          that.confirmLoading = false;
        }
      });
    },
    //获取改变后的页码
    onPageChange(pageNumber) {
      var that = this;
      this.currentPage = pageNumber;
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取每页条数
    onShowSizeChange(current, pageSize) {
      var that = this;
      this.defaultPageSize = pageSize;
      if (that.currentPage * pageSize - pageSize + 1 >= that.total_num) {
        that.currentPage = 1;
      }
      that.form.validateFields((error, values) => {
        that.getSearchList(values);
      });
    },
    //获取列表
    getSearchList(data) {
      var that = this;
      data.limit = that.defaultPageSize;
      data.page = that.currentPage;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.pageshow = false;
      that.axios
        .get("/admin/activityTask/list", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            var taskList = res.data.data;
            for (var i = 0; i < taskList.length; i++) {
              taskList[i].btns = ["详情"];
            }
            that.changeTableH();
            that.taskList = taskList;
            that.total_num = res.data.count;
            that.currentPage = res.data.currentPage;
          }
        });

      that.$nextTick(() => {
        that.pageshow = true;
      });
    },
    // 操作
    handel(btn, num) {
      var that = this;
      // //   var data;
      // that.id = num.template_id;
      if (btn == "详情") {
        that.getOne(num.task_id)
      }
    },
    getOne(task_id) {
      var that = this;
      that.$message.loading({ content: "加载中..", key: "load", duration: 0 });
      that.axios
        .get("/admin/activityTask/detail", { params: { task_id: task_id } })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            that.uploadBtn = false;
            that.visible = true;
            that.modelTitle = "详情";
            that.taskinfo = res.data.data
            that.activeKey = []
            var tasksStep = {}
            for (var i = 0; i < that.taskinfo.activity_tasks_step.length; i++) {
              tasksStep[that.taskinfo.activity_tasks_step[i].step_id] = { step_title: that.taskinfo.activity_tasks_step[i].step_title, step_description: that.taskinfo.activity_tasks_step[i].step_description, step_status: that.taskinfo.activity_tasks_step[i].step_status, rejected_reason: that.taskinfo.activity_tasks_step[i].rejected_reason, }
            }
            that.tasksStep = tasksStep
          }
        });
    },
    //筛选 导出设备
    getExport(e) {
      this.export = e;
    },
    handleSubmit(e) {
      if (e) {
        e.preventDefault();
      }
      var that = this;
      that.form.validateFields((error, values) => {
        if (that.export == 0) {
          that.currentPage = 1;
          // this.$refs.pagination.internalCurrentPage = this.nowpage;
          that.getSearchList(values);
        } else {
          var data = "";
          for (var key in values) {
            if (values[key]) {
              data += "&" + key + "=" + values[key];
            }
          }
          var myObj = {
            method: "get",
            url: "/" + that.pageNowUrl + "?export=1" + data,
            fileName: "设备列表.csv",
          };
          this.$exportMethod(myObj);
        }
      });
    },
  },
};
</script>
<style scoped>
table img {
  width: 100px;
}

.ant-table-wrapper {
  flex: 1;
}

.ant-modal-body form {
  overflow: hidden;
}

.ant-input-number {
  width: 100%;
}

.m-colorPicker {
  position: absolute;
  height: 39px;
  right: 0;
  width: 100%;
}

/deep/.m-colorPicker .colorBtn {
  margin-top: 8px;
  position: absolute;
  right: 9px;
  top: 5px;
}

/deep/.m-colorPicker .box {
  right: 0;
}

.top_board,
.task {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

}

.task {
  flex-direction: column;
  align-items: flex-start;
}

.task .task_all {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.task .task_con {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 20px
}

.task .task_con>.ant-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.top_board .ant-form-item,
.task .ant-form-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.form_title {
  font-weight: 500;
  color: #333
}
</style>